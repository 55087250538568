declare module '@mui/material/styles' {
   
  interface TypographyVariants {
    bodyBold: React.CSSProperties;
    bodyBoldItalic: React.CSSProperties;
  }

  // allow configuration using `createTheme`
   
  interface TypographyVariantsOptions {
    bodyBold?: React.CSSProperties;
    bodyBoldItalic?: React.CSSProperties;
  }
}

// Update the Typography's variant prop options
declare module '@mui/material/Typography' {
   
  interface TypographyPropsVariantOverrides {
    bodyBold: true;
    bodyBoldItalic: true;
  }
}

import { Roboto } from 'next/font/google';
import localFont from 'next/font/local';

import { createTheme } from '@mui/material/styles';

export const recoleta = localFont({
  src: './fonts/recoleta/Recoleta-SemiBold.ttf',
});
export const greycliffRegular = localFont({
  src: './fonts/greycliff/GreycliffCF-Regular.ttf',
});
export const greycliffBold = localFont({
  src: './fonts/greycliff/GreycliffCF-Bold.ttf',
});
export const greycliffLight = localFont({
  src: './fonts/greycliff/GreycliffCF-Thin.ttf',
});
export const roboto = Roboto({ subsets: ['latin'], weight: ['300', '400', '500', '700'] });

export const theme = createTheme({
  palette: {
    // type: 'light',
    primary: {
      main: '#f9bb28',
      light: '#f7c15a',
      dark: '#e09400',
      contrastText: '#000000',
    },
    secondary: {
      main: '#FFFFFF',
      light: '#FFFFFF',
      dark: '#eeeeee',
      contrastText: '#000000',
    },
    text: {
      primary: '#000000',
      secondary: '#595959',
      disabled: '#bbbbbb',
    },
    background: {
      default: '#f5f5f5',
      paper: '#ffffff',
    },
  },
  typography: {
    fontFamily: greycliffRegular.style.fontFamily,
    button: {
      fontWeight: 600,
      fontSize: '18px',
    },
    h1: {
      ...recoleta.style,
    },
    h2: {
      ...recoleta.style,
    },
    h3: {
      ...recoleta.style,
    },
    h4: {
      ...recoleta.style,
      fontSize: '36px',
    },
    h5: {
      ...recoleta.style,
    },
    h6: {
      ...recoleta.style,
    },
    bodyBold: {
      fontFamily: greycliffBold.style.fontFamily,
    },
    bodyBoldItalic: {
      fontFamily: greycliffBold.style.fontFamily,
      fontStyle: 'italic',
    },
  },
  components: {
    MuiTypography: {
      styleOverrides: {
        inherit: {
          fontFamily: greycliffRegular.style.fontFamily,
        },
      },
    },
    MuiAppBar: {
      styleOverrides: {
        root: {
          background: '#f5f5f5',
        },
      },
    },
    MuiButton: {
      styleOverrides: {
        root: {
          textTransform: 'none',
          fontSize: '18px',
          fontWeight: 900,
          borderRadius: '2em',
          padding: '0.2em 1.3em',
          fontFamily: greycliffRegular.style.fontFamily,
        },
        outlined: {
          color: '#000000',
          borderColor: '#000000',
          borderWidth: '2px',
          '&:hover': {
            borderColor: '#000000',
            borderWidth: '2px',
          },
        },
        contained: {
          color: '#ffffff',
          backgroundColor: '#000000',
          '&:disabled': {
            color: '#ffffff',
          },
        },
        text: {
          backgroundColor: 'unset',
          padding: '0',
          color: 'black',
          '&:hover': {
            borderColor: '#000000',
          },
        },
      },
    },
    MuiIconButton: {
      styleOverrides: {
        root: {
          color: '#000000',
        },
      },
    },
    MuiRadio: {
      styleOverrides: {
        root: {
          minWidth: '2em',
          fontSize: '2em',
        },
      },
    },
    MuiCheckbox: {
      styleOverrides: {
        root: {
          minWidth: '2em',
          fontSize: '2em',
          svg: {
            maxWidth: 'none',
          },
          '&.Mui-checked': {
            color: '#000000',
          },
        },
      },
    },
    MuiToggleButton: {
      styleOverrides: {
        root: {
          textTransform: 'none',
          borderRadius: '2em',
          borderColor: '#DF9F06',
          borderWidth: '2px',
          color: '#000000',
          '&:disabled': {
            color: '#e5e5e5',
          },
          fontFamily: greycliffRegular.style.fontFamily,
          '&.Mui-selected': {
            color: '#000000',
            backgroundColor: '#f9bb28',
          },
          '&.Mui-selected:hover': {
            backgroundColor: '#f9bb28',
            opacity: '80%',
          },
          '&:hover': {
            color: '#000000',
          },
        },
      },
    },
    MuiInputBase: {
      styleOverrides: {
        root: {
          fontSize: '1.3em',
          padding: '0 0.5em',
          backgroundColor: '#efefef',
        },
      },
    },
    MuiOutlinedInput: {
      styleOverrides: {
        root: {
          borderRadius: '2em',
          '&.MuiInputBase-multiline': {
            borderRadius: '16px',
          },
        },
      },
    },
    MuiAccordion: {
      styleOverrides: {
        root: {
          background: '#f5f5f5',
          boxShadow: 'none',
        },
      },
    },
  },
});

theme.typography.button = {
  ...theme.typography.button,
  [theme.breakpoints.down('lg')]: {
    fontSize: '16px',
  },
  [theme.breakpoints.down('md')]: {
    fontSize: '14px',
  },
  [theme.breakpoints.down('sm')]: {
    fontSize: '14px',
  },
};

theme.typography.body1 = {
  ...theme.typography.body1,
  color: '#46443E',
  size: '18px',
  // fontFamily: greycliffLight.style.fontFamily,
  [theme.breakpoints.down('sm')]: {
    fontSize: '1rem',
  },
};
theme.typography.body2 = {
  ...theme.typography.body2,
  color: '#46443E',
  size: '14px',
  // fontFamily: greycliffLight.style.fontFamily,
};

theme.typography.body2 = {
  ...theme.typography.body2,
  [theme.breakpoints.down('sm')]: {
    fontSize: '0.7rem',
  },
};

theme.typography.h4 = {
  ...theme.typography.h4,
  [theme.breakpoints.down('sm')]: {
    fontSize: '1.2rem',
  },
};

theme.typography.h1 = {
  ...theme.typography.h1,
  [theme.breakpoints.down('sm')]: {
    fontSize: '1.2rem',
  },
};
theme.typography.h3 = {
  ...theme.typography.h3,
  [theme.breakpoints.down('sm')]: {
    fontSize: '24px',
  },
};
theme.typography.h5 = {
  ...theme.typography.h5,
  [theme.breakpoints.down('sm')]: {
    fontSize: '1.2rem',
  },
};

theme.typography.h6 = {
  ...theme.typography.h6,
  [theme.breakpoints.down('sm')]: {
    fontSize: '0.9rem',
  },
};
