import { CancellationReason } from '@cp/database/types';
import { DateTime } from 'luxon';

export const USER_ROLES: { [key: string]: string } = {
  Admin: 'admin',
  Provider: 'provider',
  Patient: 'patient',
};

export const JOTFORM_URL_PREFIX = 'https://claritypediatrics.jotform.com';

export const AUTH_MAX_EMAIL_LINK_AGE = 30 * 60;
export const HEALTH_IT_RECORDS_URL =
  'https://www.healthit.gov/how-to-get-your-health-record/get-it/#destination';

export const PATIENT_CARE_EMAIL = 'patientcare@claritypediatrics.com';
export const INFO_EMAIL = 'info@claritypediatrics.com';

export const QUEUE_MAX_ATTEMPTS = 12;
export const QUEUE_BACKOFF_DELAY = 5000;

export const UI_DRAWER_WIDTH = 176;

// Lead times in days
export const DEFAULT_LEAD_TIME = 4;

export const APPOINTMENT_WINDOW_MONTHS = 6;

export const PASSWORD_RULES = /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z]).{8,}$/;
export const PASSWORD_MESSAGE =
  'min 8 characters, 1 upper case letter, 1 lower case letter, 1 numeric digit';

export enum EligibilityStatus {
  Active = 'active',
  Inactive = 'inactive',
  Error = 'error',
  OutOfNetwork = 'out_of_network',
  Unavailable = 'unavailable',
  SelfPay = 'self_pay',
  Invalid = 'invalid',
}

export const GOOGLE_DRIVE_SCOPES = ['https://www.googleapis.com/auth/drive'];

export const RescheduleReasonsOptions = [
  {
    label: 'Onboarding/Forms Issue',
    value: CancellationReason.pat_onboarding,
  },
  {
    label: 'Technical Issues',
    value: CancellationReason.pat_technical,
  },
  {
    label: 'Insurance Issue',
    value: CancellationReason.pat_insurance,
  },
  {
    label: 'Financial/Cost Issue',
    value: CancellationReason.pat_financial,
  },
  {
    label: 'Language Issue',
    value: CancellationReason.pat_language,
  },
  {
    label: 'Schedule Conflict',
    value: CancellationReason.pat_conflict,
  },
  {
    label: 'Other',
    value: CancellationReason.pat_other,
  },
];

export const CancellationReasonsOptions = [
  {
    label: 'Unsure of Service',
    value: CancellationReason.pat_unsure,
  },
  {
    label: 'Switched to Other Service',
    value: CancellationReason.pat_alternate,
  },
  {
    label: 'No Longer Needed',
    value: CancellationReason.pat_no_need,
  },
  ...RescheduleReasonsOptions,
];

export const NEEDS_DIAGNOSIS = 'Z03.89';

export const ADHDDiagnosisOptions = [
  {
    label: 'ADHD, Predominantly Inattentive Presentation',
    value: 'F90.0',
  },
  {
    label: 'ADHD, Predominantly Hyperactive/Impulsive Presentation',
    value: 'F90.1',
  },
  {
    label: 'ADHD, Combined Presentation',
    value: 'F90.2',
  },
  {
    label: 'Unspecified ADHD',
    value: 'F90.8',
  },
  {
    label: 'Other Specified ADHD',
    value: 'F90.9',
  },
];

export const ADHD_ICD10_CODES = ADHDDiagnosisOptions.map((option) => option.value);

export const AutismSpectrumDisorderOptions = [
  {
    label: 'Yes, Autism Spectrum Disorder (ASD), Level 1',
    value: 'F84.0',
  },
  {
    label: 'Yes, Autism Spectrum Disorder (ASD), Level 2 or 3',
    value: 'asd-level-2',
  },
  {
    label: 'No',
    value: 'no',
  },
];

export const ZENDESK_SRC_URL =
  'https://static.zdassets.com/ekr/snippet.js?key=8933b6f0-1252-436c-b708-aa8062a92d03';
export const ZENDESK_CONVERSATION_FIELDS = {
  healthieFieldId: 24780351896468,
  urlFieldId: 24795379789076,
  stateFieldId: 31740594884884,
  stateTextFieldId: 31945165012884,
};

export const COOKIE_ONBOARDING = 'clarity.onboarding';
export const COOKIE_RESCHEDULE = 'clarity.reschedule';

// HEALTHIE

export const HEALTHIE_CAREGIVER_GROUP_ID =
  process.env.DEPLOY_ENV === 'production' ? '41938' : '74406';
export const HEALTHIE_NURTURE_NO_APPOINTMENT_GROUP_ID =
  process.env.DEPLOY_ENV === 'production' ? '43113' : '68824';

export const HEALTHIE_SELF_PAY_PLAN = process.env.DEPLOY_ENV === 'production' ? '2646' : '3591';

export const HEALTHIE_PORTAL =
  process.env.NEXT_PUBLIC_DEPLOY_ENV === 'production'
    ? 'https://secure.gethealthie.com'
    : 'https://securestaging.gethealthie.com';

export const HEALTHIE_USER_URL = `${HEALTHIE_PORTAL}/users`;

export const SLUG_ADHD_PROGRAM_CONSULTATION = 'adhd-program-consultation';
export const SLUG_ADHD_PROGRAM_CONSULTATION_ES = 'adhd-program-consultation-es';
export const SLUG_ADHD_SCREEN_1 = 'adhd-screen-1';
export const SLUG_ADHD_SCREEN_1_ES = 'adhd-screen-1-es';
export const SLUG_ADHD_SCREEN_2 = 'adhd-screen-2';
export const SLUG_ADHD_SCREEN_2_ES = 'adhd-screen-2-es';
export const SLUG_ADHD_EVALUATION_1 = 'adhd-intake-part-1-with-teen-present';
export const SLUG_ADHD_EVALUATION_2 = 'adhd-intake-part-2-with-teen-present';
export const SLUG_MEDICATION_CONSULT = 'adhd-medication-consultation';
export const SLUG_MEDICATION_CONSULT_ES = 'adhd-medication-consultation-es';
export const SLUG_ADHD_INTAKE_1 = 'adhd-intake-part-1-with-teen-present';
export const SLUG_ADHD_INTAKE_2 = 'adhd-intake-part-2-with-teen-present';

export const SLUG_SERVICE_ADHD_PROGRAM_CONSULTATION = 'adhd-bpt-program-consultation';
export const SLUG_SERVICE_ADHD_EVALUATION_CONSULTATION = 'adhd-bpt-evaluation-consultation';
export const SLUG_SERVICE_ADHD_SERVICES_TEEN_PROGRAM_CONSULTATION =
  'adhd-bpt-program-consultation-13-17';
export const SLUG_SERVICE_ADHD_SERVICES_TEEN_EVALUATION_CONSULTATION =
  'adhd-bpt-evaluation-consultation-13-17';
export const SLUG_SERVICE_ADHD_MEDICATION_CONSULTATION = 'adhd-medication-management';
export const SLUG_SERVICE_ADHD_LIFESTYLE_MEDICINE = 'adhd-lifestyle-medicine';

export const FEATURE_ACCEPT_TEXAS = 'acceptTexas';

export const TEXAS_APPOINTMENT_START_DATE = DateTime.fromFormat('2025-01-02', 'yyyy-MM-dd');
