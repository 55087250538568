import { useEffect, useRef } from 'react';

function useInterval(
  callback: () => void,
  { interval, lead }: { interval: number; lead?: boolean },
): void {
  const savedCallback = useRef<() => void>(callback);

  useEffect(() => {
    savedCallback.current = callback;
  }, [callback]);

  useEffect(() => {
    const tick = (): void => savedCallback.current();

    if (lead) {
      tick();
    }

    if (interval !== null) {
      const id = setInterval(tick, interval);

      return () => clearInterval(id);
    }
  }, [interval]);
}

export default useInterval;
