import { Logout } from '@mui/icons-material';
import { Avatar, IconButton, ListItemIcon, Menu, MenuItem, Tooltip } from '@mui/material';
import { useConfirm } from 'material-ui-confirm';
import { signOut } from 'next-auth/react';
import { useRouter } from 'next/router';
import { MouseEvent, useState, type JSX } from 'react';

import { User } from '@cp/database/types';

type Item = {
  title: string;
  icon: JSX.Element;
  route: string;
};

const AccountMenu = ({ menuItems, user }: { menuItems: Item[]; user?: User }) => {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const router = useRouter();
  const confirm = useConfirm();

  const handleClick = (event: MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleItemClick = async (route: string) => {
    handleClose();
    await router.push(route);
  };

  const handleSignout = async () => {
    try {
      await confirm({
        title: 'Sign Out',
        confirmationText: 'Yes',
        cancellationText: 'No',
        content: 'Are you sure you want to sign out?',
      });

      await signOut({ callbackUrl: '/' });
    } catch {
      // Do nothing
    }
  };

  return (
    <>
      <Tooltip title="Account settings">
        <IconButton
          data-testid="accountMenuBtn"
          onClick={handleClick}
          size="small"
          sx={{ ml: 2 }}
          aria-controls={open ? 'account-menu' : undefined}
          aria-haspopup="true"
          aria-expanded={open ? 'true' : undefined}
        >
          <Avatar sx={{ width: 32, height: 32 }}>{user?.firstName?.charAt(0).toUpperCase()}</Avatar>
        </IconButton>
      </Tooltip>
      <Menu
        anchorEl={anchorEl}
        id="account-menu"
        open={open}
        onClose={handleClose}
        onClick={handleClose}
        PaperProps={{
          elevation: 0,
          sx: {
            overflow: 'visible',
            filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
            mt: 1.5,
            '& .MuiAvatar-root': {
              width: 32,
              height: 32,
              ml: -0.5,
              mr: 1,
            },
            '&:before': {
              content: '""',
              display: 'block',
              position: 'absolute',
              top: 0,
              right: 14,
              width: 10,
              height: 10,
              bgcolor: 'background.paper',
              transform: 'translateY(-50%) rotate(45deg)',
              zIndex: 0,
            },
          },
        }}
        transformOrigin={{ horizontal: 'right', vertical: 'top' }}
        anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
      >
        {menuItems.map((item) => (
          <MenuItem
            key={item.title}
            onClick={() => handleItemClick(item.route)}
            data-testid={item.title}
          >
            <ListItemIcon>{item.icon}</ListItemIcon>
            {item.title}
          </MenuItem>
        ))}
        <MenuItem onClick={handleSignout} data-testid="logoutItem">
          <ListItemIcon>
            <Logout fontSize="small" />
          </ListItemIcon>
          Logout
        </MenuItem>
      </Menu>
    </>
  );
};

export default AccountMenu;
