'use client';

import { NextPage } from 'next';
import Loading from './loading';
import { useRouter } from 'navigation';
import { useEffect } from 'react';

type Props = {
  redirect: string;
};

const RootPage: NextPage<Props> = ({ redirect }: Props) => {
  const router = useRouter();

  useEffect(() => {
    router.push(redirect);
  }, []);

  return <Loading />;
};

export default RootPage;
