import { Suspense } from 'react';

const withSuspense = (Component: any) => {
  const SuspendedComponent = (props: any) => {
    return (
      <Suspense>
        <Component {...props} />
      </Suspense>
    );
  };

  SuspendedComponent.displayName = `WithSuspense(${Component.displayName || Component.name || 'Component'})`;

  return SuspendedComponent;
};

export default withSuspense;
